// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/josefin-slab-v18-latin-regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/josefin-slab-v18-latin-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/josefin-slab-v18-latin-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/josefin-slab-v18-latin-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/josefin-slab-v18-latin-regular.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#JosefinSlab" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* josefin-slab-regular - latin */\n@font-face {\n  font-family: \"Josefin Slab\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n  src: local(\"\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\"); /* Legacy iOS */\n}", "",{"version":3,"sources":["webpack://./src/app/fonts/josefin.css"],"names":[],"mappings":"AAAA,iCAAA;AACA;EACE,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,4CAAA,EAAA,qBAAA;EACA,uTAAA,EAKmF,eAAA;AAJrF","sourcesContent":["/* josefin-slab-regular - latin */\n@font-face {\n  font-family: 'Josefin Slab';\n  font-style: normal;\n  font-weight: 400;\n  src: url('../fonts/josefin-slab-v18-latin-regular.eot'); /* IE9 Compat Modes */\n  src: local(''),\n       url('../fonts/josefin-slab-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */\n       url('../fonts/josefin-slab-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */\n       url('../fonts/josefin-slab-v18-latin-regular.woff') format('woff'), /* Modern Browsers */\n       url('../fonts/josefin-slab-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */\n       url('../fonts/josefin-slab-v18-latin-regular.svg#JosefinSlab') format('svg'); /* Legacy iOS */\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
