import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles/home.scss";

const WhtBdButton = () => (
  <RoundedButton
    onClick={() => { navigate('/whiteboard') }}>
    Whiteboard 
  </RoundedButton>
)

const Home = () => {
  const navigate = useNavigate();
		return (
    	<div className='home'>
	      <div className='titlesDiv'>
		       <h3 className='h3'>Meeting Scheduler</h3>
	         <div className='text' style={{margin: 0}}>Keep track of multiple meetings or classes</div>
	      </div>
    	  <SchedulerIntro />
    	</div>
    );
}

export default Home;

const SchedulerIntro = () => (
	<div className='schedulerIntro'>
	  <div className='meetingImg'/>
	  <MeetingText/> 
	</div>
)	

const MeetingText = () => (
	<div className='homecolumn'>
	  <div className='text'>

* Meeting participants can <strong>make and view schedules</strong> of meetings or classes they are scheduled to attend.<br/>* An online real-time multiuser <strong>whiteboard</strong> is provided.<br/>	 
* Registered participants, meeting types, dates and times, and all details can be quickly selected in <strong>drop-down menus</strong> when scheduling meetings.<br/>
* A <strong>whole series</strong> of meetings can be calculated and registered <strong>automatically</strong> by entering only the date and time of the first meeting, participants, meeting weekdays, and the number of weeks. For example, for a meeting to be held on Tuesdays and Thursdays for ten weeks beginning Tuesday, November 19 from 10:00 a.m. to 11:00 a.m., by specifying only these criteria, all 20 upcoming meetings will be automatically entered in each participant's schedule.<br/>
* A meeting's ID number can be entered to display all attendees and quickly cancel the meeting on a user's schedule if desired with a click.<br/>
* Meetings are internally registered in Coordinated Universal Time (UTC) and times are converted automatically to local time for each user on login.<br/>
* When scheduling a meeting, the user is automatically alerted to any conflicts with existing meetings and is given a chance to reschedule or leave the overlapping meeting time as is.<br/>
* Login and access are authenticated with <strong>JWT</strong> (or Django <strong>Session Authentication</strong> in the Django version).<br/><br/>

This application was created with <strong>React</strong>, <strong>Node.js</strong>, Javascript, Express server, Nginx, Socket.IO, Canvas, <strong>PostgreSQL</strong>, Knex, Webpack, Flexbox, SCSS, and JSON Web Tokens (JWT). A version using <strong>MongoDB</strong> and another using <strong>Django/Python</strong> + React are also available.<br/>

	</div>
	  <br/><br/>
	  <div className='smalltext'>
	    Try it by logging in as "Bill" with password "bb".	
	  </div>
	</div>
)

