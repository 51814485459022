import React from 'react';
import 'core-js/stable';
import "regenerator-runtime/runtime";
import ReactDOM, { render } from 'react-dom';
import App from './app';

render(
	  <App />, document.getElementById("app")
	 );

