import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { nanoid } from 'nanoid';
import "./styles/frontPage.scss";
import "../fonts/josefin.css";
import "../fonts/quicksand.css";
// Note the path for images below is the path from this source file
import whiteboardimg from "../../../frontpage/whiteboard.jpg";
import blueline from "../../../frontpage/100x1pxBlueLine.png";
import mtgSchedulerPic from "../../../frontpage/schedulePage.jpg";
import whiteboardPic from "../../../frontpage/whiteboard.jpg";
import labVIEWATEPic from '../../../frontpage/ATE.png';
import labVIEWScreenPic from '../../../frontpage/labview_screen.png';
import calstationSwitchesPic from '../../../frontpage/CalStation2SwitchesCShp.png';
import atePic from '../../../frontpage/ATE.png';
import blueLinePic from '../../../frontpage/100x1pxBlueLine.png';
import pythonSVMPic from '../../../frontpage/PythonSVM.png';
import pHPPic from '../../../frontpage/PHPMySQL.png';
import sbclPic from '../../../frontpage/SBCL.png';

function FrontPage() {

  const navigate = useNavigate();

	const handleClick = () => {
    console.log("I saw the click.");
    alert("I saw the click.");
	}
	
	const WhtBdButton = () => (
    <button className = "roundedButton1"
      onClick={handleClick}>
      Whiteboard 
    </button>
  );

  const Projects = () => (
  	<div className = "projects">
  	  <MtgSched/>
  	  <Whiteboard/>
  	  <LabVIEW/>
  	  <PythonSVMsbcl/>
  	</div>
  );	
  
  const MtgSched = () => (
  	<div className = "article">
  	  <MtgSchArticleText/> 
      <img className = "articlePic" src={mtgSchedulerPic} onClick={() => {navigate('/mtgScheduler')}}/> 
  	</div>
  );
   
  const Whiteboard = () => (
  	<div className = "article">
  	  <WhiteboardArticleText/> 
      <img className = "articlePic" src={whiteboardPic} onClick={() => {navigate('/mtgScheduler/whiteboard')}}/> 
  	  <CSharpArticleText/> 
      <img className = "articlePic" src={calstationSwitchesPic}/> 
  	</div>
  );
  
  const LabVIEW = () => (
  	<div className = "article">
  	  <LabVIEWArticleText/> 
      <img className = "articlePic" src={labVIEWScreenPic}/> 
      <img className = "articlePic" src={labVIEWATEPic}/> 
  	</div>
  );
  
	const PythonSVMsbcl = () => (
  	<div className = "article">
  	  <PythonSVMArticleText/> 
      <img className = "articlePic" src={pythonSVMPic}/> 
  	  <PHPArticleText/> 
      <img className = "articlePic" src={pHPPic}/> 
  	  <SBCLArticleText/> 
  	  <div className = "flexRow">
        <img className = "sBCLImg" src={sbclPic}/> 
  	    <div className = "flexColumn">
  	      <div className = "sBCLTextDiv">Steel</div>
  	      <div className = "sBCLTextDivOrange">Bank</div>
  	      <div className = "sBCLTextDivGrey">Common</div>
  	      <div className = "sBCLTextDivMaroon">Lisp</div>
  	    </div>
  	  </div>
  	</div>
  );
    
	const MtgSchArticleText = () => (
  	<div className = "articleText">
  	  <div className = "itemTitle">
  	    <b>Meeting Scheduler</b>&nbsp; online app<br/>
  	  </div>
  	  <div className = "textDiv22">
  	*<strong> Full stack web application demos</strong> <br/>* <strong>Meeting</strong> schedules can be created for each authenticated user. <br/>* Runs in browsers on desktops, tablets, and smartphones.<br/>* An online real-time <strong>whiteboard</strong> is provided.<br/>* Created with React, NodeJS, Flexbox, PostgreSQL, Express server, Nginx, and Socket.IO. <br/>* DJANGO/Python and Mongo DB versions also on Github<br/>* <b>Click on the image below for a demo.</b><br/>* See source code on GitHub: github.com/tom4244.	
  	  </div>
  	</div>
  );
	
  const WhiteboardArticleText = () => (
  	<div className = "articleText">
  	<div className = "itemTitle">
		  <b>Whiteboard</b> online app<br/>
		</div>
  	<div className = "textDiv22">
  	* Users can draw freehand and geometric objects and display text in real time on this commonly viewable whiteboard from anywhere in the world. <br/>* Runs on PC, Mac, and mobile devices in popular browsers.<br/>* Created with React, NodeJS, HTML5 Canvas, Flexbox, and Socket.IO.<br/>* <b>Click on the image below for a demo.</b><br/>* Source code viewable on GitHub.	
  	</div>
  	</div>
  );
  
  const LabVIEWArticleText = () => (
  	<div className = "articleText">
  	  <div className = "itemTitle">
  	    <b>LabVIEW</b> Electronic Test Software and ATE Design<br/>
  	  </div>
  	  <div className = "textDiv22">
  	* 11 years experience writing electronic test applications using LabVIEW graphical software for implantable medical device testing and sounding rocket guidance electronics testing<br/>* 11 years experience designing Automated Test Equipment systems 
      </div>
  	</div>
  );
	
  const CSharpArticleText = () => (
  	<div className = "articleText">
  	  <div className = "itemTitle">
  	    Electronic Device Test Software in <b>C#</b> and <b>C</b>
  	  </div>
  	  <div className = "textDiv22"> 
     1 year and 9 months experience writing test software for a data acquisition electronics manufacturing company using C#, WPF, XAML and LabWindows/CVI 
  	  </div>
  	</div>
  );
  
  const PythonSVMArticleText = () => (
  	<div className = "articleText">
  	<div className = "itemTitle">
  	MSCS Thesis using <b>Python</b> and Support Vector Machines
  	</div>
  	<div className = "textDiv22"> 
   Master's Thesis at California State University at Fullerton used Python to implement Support Vector Machines for a Computational Linguistics application. Artificial Intelligence and Programming Languages were areas of focus for a Master's Degree in Computer Science.
  	</div>
  	</div>
  );
  
  const PHPArticleText = () => (
  	<div className = "articleText">
  	<div className = "itemTitle">
    <b>PHP</b> and <b>MySQL</b> Databases Class Term Project 
  	</div>
  	<div className = "textDiv22">
   Used MySQL and PHP 
  	to implement a convenient class schedule database for students and faculty members. 
    </div> 
  	</div>
  );
	
  const SBCLArticleText = () => (
  	<div className = "articleText">
  	<div className = "itemTitle">
    <b>Steel Bank Common Lisp</b> projects  
  	</div>
  	  <div className = "textDiv22">
   Used Common Lisp for various graduate school projects including a programming language compiler.
      </div> 
  	</div>
  );

	return (
    	<div className = "home">
	         <div className = "textDiv">A few examples of coding experience in various languages with samples viewable on GitHub and a couple of live demos.</div>
    	  <Projects />
    	</div>
    );
}

export default FrontPage;

