export default {
	jwtSecret: 'yoursecretkeyforjsonwebtoken',
  //if using https, put keyfile, cafile, and certfile here
  keyfile: '/home/tom/meeting_scheduler/.certs/privkey1.pem',
	cafile: '/home/tom/meeting_scheduler/.certs/fullchain1.pem',
	certfile: '/home/tom/meeting_scheduler/.certs/cert1.pem',
	port: 3000,
	// socketAddr: 'http://localhost',
	socketAddr: 'https://tbcan.net',
	dbUser: 'postgres',
	//dbPassword should be created and listed here
	//dbPassword: 'postgres'
	dbPassword: 'pg78dogD'
}

